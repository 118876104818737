import { cloneDeep } from 'lodash-es';

import { EnvironmentConfig } from '@api/environmentsApi/models/EnvironmentConfig';
import { EnvironmentType } from '@api/environmentsApi/models/EnvironmentType';
import { ProvisioningStatus } from '@api/environmentsApi/models/ProvisioningStatus';
import { Environment, EnvironmentHierarchyLookup } from '@api/hierarchyApi/models/Hierarchy';
import { ProvisioningRequest } from '@api/provisioningApi/provisioningApi';
import { AuthHelper } from '@shared/auth/AuthHelper';
import { FieldValidator } from '@shared/components/ValidatedField/ValidatedField';
import { t } from '@shared/i18n';
import { Dictionary } from '@shared/interfaces/Dictionary';
import { APP_STORE } from '@shared/stores/constants';
import { guidRegexString } from '@shared/utilities/guid';

export const tagRegex = /^\w[\w ]{0,59}$/;

export const appIdValidator = (): FieldValidator[] => {
	return [
		{
			type: 'regex',
			regex: new RegExp(guidRegexString),
			errorMessage: t('Environment.Picker.Editor.Validation.AppId'),
		},
	];
};

export const apiRequestValidator = (siblingApiIds: string[]): FieldValidator[] => {
	const apiIdentifierRegexStr = `^[\\w-]+$`;

	return [
		{
			type: 'regex',
			regex: new RegExp(apiIdentifierRegexStr),
			errorMessage: t('General.NameValidation'),
		},
		{
			type: 'unique',
			values: siblingApiIds,
		},
	];
};

export const checkIfActive = (env: EnvironmentConfig | EnvironmentHierarchyLookup) => env?.status === ProvisioningStatus.Active;

export const checkIfProvisioning = (env: EnvironmentConfig | EnvironmentHierarchyLookup) =>
	env.status === ProvisioningStatus.New || env.status === ProvisioningStatus.Creating;

export const checkIfProcessing = (env: EnvironmentConfig | EnvironmentHierarchyLookup) =>
	env.status === ProvisioningStatus.New || env.status === ProvisioningStatus.Creating || env.status === ProvisioningStatus.SoftDeleting;

export const checkIfFailed = (env: EnvironmentConfig | EnvironmentHierarchyLookup) =>
	env.status === ProvisioningStatus.CreateFailed || env.status === ProvisioningStatus.Error;

/**
 * Easier to mock as a method
 * Only distinguishes between PROD and everything else (INT, DEV, Local)
 * Use hasAtLeastOneSpartaApi() to detect local+dev sites only
 */
export const isProduction = () => {
	return window.siteConfig.environment === EnvironmentType.Production;
};

export const getEnvironmentIdFromTestHooks = () => {
	return window.testHooks.stores[APP_STORE].environment?.envid || window.testHooks.stores[APP_STORE].envIdToLoad;
};

export const getAssignedRolesFromTestHooks = () => {
	return window.testHooks.stores[APP_STORE].tenantConfig?.data?.assignedRoles || [];
};

export const getTenantId = () => {
	return AuthHelper.user?.tenantId;
};

export const getTenantName = () => {
	return AuthHelper.tenantName;
};

export const isGoodProvisioningRequest = (request: ProvisioningRequest, firstEnv?: boolean) => {
	const hasRequired = request?.geography && typeof request?.isTest === 'boolean';

	return firstEnv ? hasRequired : hasRequired && request?.name;
};

/**
 * @deprecated The method should not be used. Use AppStore.baseUrl instead. Only use if store access isn't possible.
 */
export const tenantIdAndEnvId = (environmentId?: string) => {
	const tenantId = getTenantId();
	const envId = environmentId ?? getEnvironmentIdFromTestHooks();

	return `${tenantId}/env/${envId}`;
};

export const getParentsListInHierarchyOrder = (
	hierarchyLookup: Dictionary<EnvironmentHierarchyLookup>,
	envId: string,
	envsInHierarchyOrder: EnvironmentHierarchyLookup[],
): EnvironmentHierarchyLookup[] => {
	let curr = hierarchyLookup[envId];
	const clonedEnvsInHierarchyOrder = cloneDeep(envsInHierarchyOrder); // to keep function pure for render
	if (!curr) {
		return clonedEnvsInHierarchyOrder;
	}
	clonedEnvsInHierarchyOrder.push(curr);

	if (!curr.parentEnvironmentId) {
		return clonedEnvsInHierarchyOrder.reverse();
	} else {
		const parent = hierarchyLookup[curr.parentEnvironmentId];
		curr = parent;

		return getParentsListInHierarchyOrder(hierarchyLookup, curr?.envid, clonedEnvsInHierarchyOrder);
	}
};

export const getParentsInfo = (lookup: Dictionary<EnvironmentHierarchyLookup>, currentEnvId: string) => {
	const parentList = getParentsListInHierarchyOrder(lookup, currentEnvId, []);

	return { parentIds: parentList.map((env) => env.envid), path: parentList.map((env) => env.name).join('/') };
};

export const getEnvironmentStatusText = (status: ProvisioningStatus) => {
	const { New, Creating, Active, CreateFailed, SoftDeleting, SoftDeleteFailed, Error } = ProvisioningStatus;
	let text: string;
	switch (status) {
		case New: {
			text = t('General.New');
			break;
		}
		case Creating: {
			text = t('General.Creating');
			break;
		}
		case Active: {
			text = t('General.Active');
			break;
		}
		case SoftDeleting: {
			text = t('General.Deleting');
			break;
		}
		case SoftDeleteFailed: {
			text = t('General.SoftDeleteFailed');
			break;
		}
		case Error:
		case CreateFailed: {
			text = t('General.Failed');
			break;
		}
		default:
			break;
	}

	return text;
};

export const isValid = (env: Environment) => {
	if (!env) {
		return false;
	}

	return env.hasAccess && env.status === ProvisioningStatus.Active;
};
