import { AzureMapsStore } from '@shared/components/Maps/AzureMaps/AzureMapsStore';

export const AAD_APP_STORE = 'STORE_AAD_APP';
export const ANNOTATIONS_STORE = 'STORE_ANNOTATIONS';
export const API_INTEGRATION_STORE = 'STORE_API_INTEGRATION';
export const APP_STORE = 'STORE_CONSTANT_APP';
export const ASSESSMENTS_STORE = 'STORE_CONSTANT_ASSESSMENTS';
export const AZURE_MAPS_STORE = 'STORE_AZURE_MAPS';
export const AUDIT_STORE = 'STORE_CONSTANT_AUDIT';
export const BLUEPRINTS_STORE = 'STORE_CONSTANT_BLUEPRINTS';
export const CASE_MANAGEMENT_STORE = 'STORE_CASE_MANAGEMENT';
export const CASE_QUEUES_STORE = 'STORE_CASE_QUEUES';
export const COPILOT_STORE = 'STORE_CONSTANT_COPILOT';
export const CUSTOM_ACTIONS_STORE = 'STORE_CONSTANT_CUSTOM_ACTIONS';
export const DATA_UPLOAD_PAGE_STORE = 'STORE_CONSTANT_DATA_UPLOAD_PAGE_STORE';
export const DATA_UPLOAD_STATUS_STORE = 'STORE_CONSTANT_DATA_UPLOAD_STATUS';
export const DEVICE_FINGER_PRINTING_STORE = 'STORE_DEVICE_FINGER_PRINTING';
export const DYNAMIC_COLUMNS_STORE = 'STORE_CONSTANT_DYNAMIC_COLUMNS';
export const ENVIRONMENT_PREFERENCES_STORE = 'STORE_CONSTANT_ENVIRONMENT_PREFERENCES';
export const ENVIRONMENT_STORE = 'STORE_CONSTANT_ENVIRONMENT_STORE';
export const EXTERNAL_ASSESSMENTS_STORE = 'STORE_CONSTANT_EXTERNAL_ASSESSMENTS';
export const EXTERNAL_CALL_STORE = 'STORE_CONSTANT_EXTERNAL_CALL';
export const FCRA_STORE = 'STORE_CONSTANT_FCRA';
export const FEATURE_STORE = 'STORE_CONSTANT_FEATURE_STORE';
export const FQL_STORE = 'STORE_CONSTANT_FQL';
export const GLOBAL_FUNCTIONS_STORE = 'STORE_CONSTANT_GLOBAL_FUNCTIONS';
export const GRAPH_STORE = 'STORE_CONSTANT_GRAPH';
export const JOYRIDE_STORE = 'STORE_CONSTANT_JOYRIDE';
export const LINK_ANALYSIS_STORE = 'STORE_CONSTANT_LINK_ANALYSIS';
export const LISTS_STORE = 'STORE_CONSTANT_LISTS';
export const LOSSPREVENTION_ORCHESTRATION_STORE = 'STORE_LOSS_PREVENTION_ORCHESTRATION_STORE';
export const LOSSPREVENTION_REPORTS_STORE = 'STORE_LOSS_PREVENTION_REPORTS_STORE';
export const NAVIGATION_STORE = 'STORE_CONSTANT_NAVIGATION';
export const NOTIFICATIONS_STORE = 'STORE_CONSTANT_NOTIFICATIONS';
export const ONTOLOGY_STORE = 'STORE_CONSTANT_ONTOLOGY';
export const OPT_IN_STORE = 'STORE_OPT_IN';
export const PAYG_STORE = 'STORE_CONSTANT_PAYG';
export const POWERBI_STORE = 'STORE_POWERBI';
export const RBAC_STORE = 'STORE_CONSTANT_RBAC';
export const RULES_STORE = 'STORE_CONSTANT_RULES';
export const SEARCH_STORE = 'STORE_CONSTANT_SEARCH';
export const SUBSCRIPTION_STORE = 'STORE_CONSTANT_SUBSCRIPTION';
export const SUPPORT_ACTIONS_STORE = 'STORE_CONSTANT_SUPPORT_ACTIONS';
export const SUPPORT_STORE = 'STORE_CONSTANT_SUPPORT';
export const TEMPLATES_STORE = 'STORE_CONSTANT_TEMPLATES';
export const TENANT_USER_PREFERENCES_STORE = 'STORE_CONSTANT_TENANT_USER_PREFERENCES';
export const UX_CONFIG_STORE = 'STORE_UX_CONFIG';
export const VELOCITY_GROUPS_STORE = 'STORE_VELOCITY_GROUPS';

export interface AllStores {
	[AZURE_MAPS_STORE]: AzureMapsStore;
}
