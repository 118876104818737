/* eslint-disable no-restricted-syntax */

export const setCookieValue = (key: string, value: string | number | boolean, path = '/', expires?: Date, maxAge?: number) => {
	let newCookie = `${key}=${value}; path=${path};`;

	if (expires) {
		newCookie = `${newCookie} expires=${expires.toUTCString()};`;
	}

	if (maxAge) {
		newCookie = `${newCookie}  max-age=${maxAge}`;
	}

	document.cookie = newCookie;
};

export const getCookieValue = (key: string): string => {
	return document.cookie
		.split(';')
		.find((kvp: string) => kvp.trim().startsWith(key))
		?.trim()
		.substr(`${key}=`.length);
};

export const deleteCookie = (key: string, path = '/') => {
	document.cookie = `${key}=; path=${path}; max-age=0`;
};
