import dayjs from 'dayjs';
import i18next, { InitOptions, TOptions } from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import I18NextHttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { CUSTOM_SETTINGS, isLocalDevelopment } from '@shared/constants';
import daDK from '@shared/resources/da-DK/strings.json';
import deDE from '@shared/resources/de-DE/strings.json';
import enUS from '@shared/resources/en-US/strings.json';
import esES from '@shared/resources/es-ES/strings.json';
import frFR from '@shared/resources/fr-FR/strings.json';
import itIT from '@shared/resources/it-IT/strings.json';
import nlNL from '@shared/resources/nl-NL/strings.json';
import ptBR from '@shared/resources/pt-BR/strings.json';

export const i18n = i18next;

// shortcut wrapper for i18n.t
export const t = (keys: string | string[], tOpts?: TOptions) => i18n.t(keys, tOpts);
export const missingKeyFn = (key: string, defaultValue?: string) => {
	if (key.startsWith(CUSTOM_SETTINGS)) {
		const path = key.split('.');

		return path[path.length - 1];
	}

	return defaultValue ?? `<<UNKNOWN_TRANSLATION_KEY '${key}'>>`;
};
export const missingFn = (_: string, match: string[]) => `<<INTERPOLATION_ERROR key '${match[1]}'>>`;
export const DEFAULT_NS = 'translation';
export const exists = (key: string) => i18n.exists(key);

const locales = Object.keys(__$localeMapping);

export const LOC_QUERY_STRING_PARAM = 'mkt';
export const LOC_LOCAL_STORAGE_KEY = 'DFP/Market';

// This will be the path to the resource e.g. (/dist/strings-en-US.json)
const stringFiles = {
	'da-DK': daDK,
	'de-DE': deDE,
	'es-ES': esES,
	'en-US': enUS,
	'fr-FR': frFR,
	'it-IT': itIT,
	'nl-NL': nlNL,
	'pt-BR': ptBR,
};

// require locale so that they get picked up by webpack
const loadLocaleModule = (language: string) => {
	/* eslint-disable import/no-unassigned-import, @typescript-eslint/no-require-imports */
	switch (language) {
		case 'en-US':
			require('dayjs/locale/en');
			break;
		case 'de-DE':
			require('dayjs/locale/de');
			break;
		case 'es-ES':
			require('dayjs/locale/es');
			break;
		case 'fr-FR':
			require('dayjs/locale/fr');
			break;
		case 'it-IT':
			require('dayjs/locale/it');
			break;
		case 'nl-NL':
			require('dayjs/locale/nl');
			break;
		case 'pt-BR':
			require('dayjs/locale/pt-br');
			break;
		default:
			break;
	}
	/* eslint-disable import/no-unassigned-import, @typescript-eslint/no-require-imports */
};

export const initializeLocalization = async () => {
	const options: InitOptions = {
		load: 'currentOnly',
		defaultNS: DEFAULT_NS,
		supportedLngs: locales,
		fallbackLng: locales[0],
		detection: {
			/**
			 * detection strategy:
			 * - only attempt to load from browser (navigator) on first load
			 * - otherwise always prefer value in localStorage, which will be set on first load by i18n or by user through language selection
			 * - allow querystring override for testing
			 */
			order: ['querystring', 'localStorage', 'navigator'],
			lookupQuerystring: LOC_QUERY_STRING_PARAM,
			lookupLocalStorage: LOC_LOCAL_STORAGE_KEY,
		},

		interpolation: {
			escapeValue: false, // not necessary for react due to react auto-escaping (unless you use dangerouslySetInnerHTML)
		},

		// will log missing keys to console in local development
		debug: isLocalDevelopment,

		parseMissingKeyHandler: missingKeyFn,
		missingInterpolationHandler: missingFn,

		// do not asynchronously init; allow consumers to wait on initialize promise
		initImmediate: false,
	};

	const backend = new I18NextHttpBackend(null, {
		loadPath: (languages: string[]) => {
			// we don't have i18next-multiload-backend-adapter, languages will only have 1 item each
			const language = languages?.[0];

			return stringFiles[language];
		},
	});

	return i18next
		.use(I18nextBrowserLanguageDetector)
		.use(backend)
		.use(initReactI18next)
		.init(options)
		.then(() => {
			loadLocaleModule(i18next.language);

			// setup locale for all dates
			dayjs.locale(__$localeMapping[i18next.language].dayjs);

			// add lang attr to html for accessibility
			document.getElementsByTagName('html')[0].setAttribute('lang', i18next.language);
		});
};

export const getLanguageCode = () => i18n.language.split('-')[0];
export const getIsRtl = () => i18n.dir(getLanguageCode()) === 'rtl';
export const getProductName = () => t('General.ProductName');
export const getMicrosoftTitle = () => t('General.MicrosoftTitle', { productName: getProductName() });
export const addString = (key: string, value: string) => {
	i18n.addResource(i18n.language, DEFAULT_NS, key, value);
};
export const addStrings = (values: Record<string, string>) => {
	i18n.addResources(i18n.language, DEFAULT_NS, values);
};
