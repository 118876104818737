import { DomainHint } from '@api/environmentsApi/models/DomainHint';
import { AccountInfo, AuthError, Configuration, PublicClientApplication, RedirectRequest, SilentRequest } from '@azure/msal-browser';
import { deleteCookie, getCookieValue, setCookieValue } from '@shared/utilities/cookieUtilities';

import { i18n } from '../i18n';
import { logError } from '../logging/logging';

const REDIRECT_URI_PARAM = 'RU';

export class AuthHelper {
	public static AAD_DEFAULT_TENANT = 'organizations';
	public static tenantName: string;
	public static user: AccountInfo = null;

	private static tenant: string;
	private static queryAutomaticLogin = 'AutomaticLogin';
	private static signInPathname = '/signin';
	private static clientApp: PublicClientApplication = null;

	private static get msalConfig(): Configuration {
		const tenant = AuthHelper.tenant || AuthHelper.AAD_DEFAULT_TENANT;

		return {
			auth: {
				clientId: window.siteConfig.appId,
				authority: `https://login.microsoftonline.com/${tenant}`,
				redirectUri: window.location.origin,
				postLogoutRedirectUri: window.location.origin,
				navigateToLoginRequestUrl: true,
			},
			cache: {
				cacheLocation: 'localStorage',
				storeAuthStateInCookie: false,
			},
			system: {
				loadFrameTimeout: 30000,
				navigateFrameWait: 0, // by default, this is 500ms to account for issues with IE and iFrames; we don't care about IE so we can set to 0
			},
		};
	}

	private static loginRequest: RedirectRequest = {
		scopes: [],
	};

	private static get isOnSignInPath(): boolean {
		return location.pathname?.toLocaleLowerCase() === AuthHelper.signInPathname;
	}

	public static get userName(): string {
		const user = AuthHelper.user;

		if (!user?.idTokenClaims) {
			return null;
		}

		return user.name || user.username || user.idTokenClaims['name'] || null;
	}

	public static get wids(): string[] {
		return AuthHelper.user.idTokenClaims?.['wids'] as string[];
	}

	public static get isAzureGlobalAdmin() {
		// https://docs.microsoft.com/en-us/azure/active-directory/roles/permissions-reference
		return AuthHelper.wids?.includes('62e90394-69f5-4237-9190-012177145e10');
	}

	public static get isAzureAppAdmin() {
		// https://docs.microsoft.com/en-us/azure/active-directory/roles/permissions-reference
		return AuthHelper.wids?.includes('9b895d92-2cd3-44c7-9d02-a6ac2d5ea5c3');
	}

	public static get isAzureCloudAppAdmin() {
		// https://docs.microsoft.com/en-us/azure/active-directory/roles/permissions-reference
		return AuthHelper.wids?.includes('158c047a-c907-4556-b7ef-446551a6b5f7');
	}

	public static signOutLinkUrl = (redirectUrlString = window.location.origin): string => {
		return `https://login.microsoftonline.com/${AuthHelper.user.tenantId}/oauth2/logout?post_logout_redirect_uri=${encodeURIComponent(
			redirectUrlString,
		)}`;
	};

	private static setCurrentUserAndTenant = (account: AccountInfo) => {
		AuthHelper.user = account;

		/**
		 * In a multi-tenant scenario, user can be signed in under different tenants with the same username.
		 * We need to call acquireTokenSilent with the authority that reflects the tenant of the signed in user (not 'organizations').
		 * Otherwise MSAL does not know which token to return and throw error
		 */
		AuthHelper.tenant = account.tenantId;
	};

	public static initialize = async (domainHint: DomainHint, onAuthenticatedCallback: (user?: AccountInfo) => void) => {
		AuthHelper.tenant = domainHint?.tenantName; // changes to tenant ID after login
		AuthHelper.tenantName = domainHint?.tenantName; // stays tenant name hint forever

		AuthHelper.clientApp = new PublicClientApplication(AuthHelper.msalConfig);

		try {
			await AuthHelper.clientApp.initialize();
			await AuthHelper.clientApp.handleRedirectPromise();

			const hintTenantName = domainHint?.tenantName;
			const hintTenantId = domainHint?.tenantId;

			const account = hintTenantId
				? AuthHelper.clientApp.getAllAccounts().find((a) => a.tenantId === hintTenantId)
				: AuthHelper.clientApp.getAllAccounts()?.[0];

			// Go to sign in page when the tenant hint does not match the logged in user's tenant.
			const cachedUserTenantDoesNotMatchDomainHintTenant =
				hintTenantName !== AuthHelper.AAD_DEFAULT_TENANT && hintTenantId !== account?.tenantId;

			if (!account || AuthHelper.isOnSignInPath || cachedUserTenantDoesNotMatchDomainHintTenant) {
				await AuthHelper.loginOrSendToSignin(onAuthenticatedCallback);

				return;
			}

			AuthHelper.setCurrentUserAndTenant(account);

			onAuthenticatedCallback(account);
		} catch (e) {
			AuthHelper.redirectCallback(e);
		}
	};

	public static goToSignInPage = () => {
		const url = new URL(`${window.siteConfig.interstitialSignInUrl.replace('{LOCALE}', i18n.language)}`);
		url.searchParams.set(REDIRECT_URI_PARAM, `${window.location.origin}${AuthHelper.signInPathname}`);

		setCookieValue(REDIRECT_URI_PARAM, window.location.href);

		window.location.replace(url.href);
	};

	public static acquireTokenSilent = async (scopes?: string[], forceRefresh = false, requestOverrides?: Partial<SilentRequest>) => {
		const request: SilentRequest = {
			scopes,
			...requestOverrides,
			account: AuthHelper.user,
			forceRefresh,
			authority: AuthHelper.msalConfig.auth.authority, // Need this for guest account
		};

		try {
			const authResult = await AuthHelper.clientApp.acquireTokenSilent(request);

			return authResult.accessToken;
		} catch (e) {
			if (!AuthHelper.requireInteraction(e.errorCode)) {
				logError(e);
				throw e;
			}

			await AuthHelper.clientApp.acquireTokenRedirect(request);
		}
	};

	public static redirectToLogin = async () => {
		await AuthHelper.clientApp.loginRedirect(AuthHelper.loginRequest);
	};

	private static loginOrSendToSignin = async (sendToSignin: () => void) => {
		if (AuthHelper.isOnSignInPath) {
			const redirectUri = getCookieValue(REDIRECT_URI_PARAM);

			if (redirectUri) {
				deleteCookie(REDIRECT_URI_PARAM);
			}

			AuthHelper.redirectWithAutoLogin(redirectUri ?? location.origin);

			return;
		}

		const autoLogin = AuthHelper.shouldAutoLogin();
		// prettier-ignore
		if (autoLogin) { // CodeQL [SM01513] False positive. The only thing the user could do is skip the interstitial page. Not a security bug.
			await AuthHelper.redirectToLogin();

			return;
		}

		sendToSignin();
	};

	public static logoutHandler = (postLogoutRedirectUri?: string) => (): void => {
		AuthHelper.clientApp.logoutRedirect({ postLogoutRedirectUri });
	};

	public static redirectWithAutoLogin = (redirectUri: string) => {
		const url = new URL(redirectUri);
		url.searchParams.set(AuthHelper.queryAutomaticLogin, 'true');

		window.location.assign(url.href);
	};

	private static shouldAutoLogin = (): boolean => {
		// The Commerce Sign Up platform sends this parameter to us in the redirect following a successful sign up.
		// Other sites can take advantage of this and send the same parameter if they want to bypass the interstitial page.
		const urlParams = new URLSearchParams(window.location.search.toLocaleLowerCase());
		const automaticLogin = urlParams.get(AuthHelper.queryAutomaticLogin.toLocaleLowerCase());

		return automaticLogin && automaticLogin.toLocaleLowerCase() === 'true';
	};

	private static redirectCallback = (error: AuthError) => {
		if (error) {
			if (error.errorCode === 'login_required') {
				AuthHelper.clientApp.loginRedirect(AuthHelper.loginRequest);

				return;
			}

			logError(error);
		}
	};

	private static requireInteraction = (errorMessage: AuthError['errorMessage']): boolean => {
		if (!errorMessage?.length) {
			return false;
		}

		return (
			errorMessage.includes('consent_required') ||
			errorMessage.includes('interaction_required') ||
			errorMessage.includes('login_required') ||
			errorMessage.includes('no_tokens_found') ||
			errorMessage.includes('invalid_grant')
		);
	};
}
