import { AuthHelper } from '@shared/auth/AuthHelper';
import { initializeLocalization } from '@shared/i18n';
import { setupLogging } from '@shared/logging/appInsights';
import { logError } from '@shared/logging/logging';
import { setupSpartaApi } from '@shared/spartaApiPicker';

try {
	window.__$ver = `build: ${__$buildNumber} | build time: ${__$buildTime}`;

	// adapted from https://stackoverflow.com/a/54200382
	if (typeof Node === 'function' && Node.prototype) {
		const originalRemoveChild = Node.prototype.removeChild;
		Node.prototype.removeChild = function (child) {
			if (child.parentNode !== this) {
				const childEl = child as unknown as Element;

				logError(new Error('Cannot remove a child from a different parent'), {
					child: childEl.outerHTML,
					expectedParent: (this as unknown as Element).outerHTML,
					actualParent: childEl.parentElement?.outerHTML,
				});
			}

			// eslint-disable-next-line prefer-rest-params
			return originalRemoveChild.apply(this, arguments);
		};
	}
	setupLogging();

	setupSpartaApi();

	const locPromise = initializeLocalization();
	const bootImport = locPromise.then(async () => import(/* webpackChunkName: "Boot", webpackPreload: true */ './boot'));

	AuthHelper.initialize({ tenantName: window.siteConfig.tenantName, tenantId: window.siteConfig.tenantId }, async (user) => {
		// wait for localization to finish initialization before rendering anything
		await locPromise;

		if (!user) {
			AuthHelper.goToSignInPage();

			return;
		}

		const { boot } = await bootImport;
		await boot(user);
	});
} catch (e) {
	logError(e);
}
