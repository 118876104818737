const reviewParams = ':eventType/:envId/:id';
export const velocitiesPath = 'velocities';
export const globalFunctionsPath = 'functions';

export enum RuleRoutes {
	AccountCreation = 'accountcreation',
	AccountLogin = 'accountlogin',
	Purchase = 'purchase',
	PurchaseRules = 'purchaserules',
	RoutingRules = 'routingrules',
	Rules = 'rules', // e.g. it's a part of 'fraud/purchase/rules' and 'data/rules/purchaserules'
}

export const RoutePaths = {
	Dashboard: '/',
	Fraud: {
		Root: '/fraud',
	},
	Purchase: {
		Root: '/fraud/purchase',
		Rules: '/fraud/purchase/rules',
		Support: '/fraud/purchase/support',
	},
	AccountCreation: {
		Root: '/fraud/accountcreation',
		Rules: '/fraud/accountcreation/rules',
	},
	AccountLogin: {
		Root: '/fraud/accountlogin',
		Rules: '/fraud/accountlogin/rules',
	},
	Assessments: {
		Root: '/fraud/assessments',
		RootV2: '/fraud/assessments/:assessmentId',
		Monitoring: '/fraud/assessments/:assessmentId/monitor',
		Rules: '/fraud/assessments/:assessmentId/rules',
		Configuration: '/fraud/assessments/:assessmentId/configuration',
		Setup: '/fraud/assessments/setup',
		EditSchema: '/fraud/assessments/:assessmentId/schemaDetails',
		CreateLabel: '/fraud/assessments/:assessmentId/observations/create/label',
		CreateObservation: '/fraud/assessments/:assessmentId/observations/create',
		EditLabel: '/fraud/assessments/:assessmentId/observations/label',
		EditObservation: '/fraud/assessments/:assessmentId/observations/:observationId',
	},
	Integration: {
		Dashboard: '/integration',
		AAD: {
			Create: '/aadapp/create',
			Manage: '/aadapp/manage',
		},
		DeviceFingerprinting: {
			Try: '/devicefingerprinting/try',
			Setup: '/devicefingerprinting/setup',
		},
		ApiIntegration: {
			Setup: '/api/:id',
		},
	},
	CaseManagement: {
		Root: '/casemanagement',
		QueueDashboard: '/casemanagement/queues',
		Queue: '/queues/:queueId',
		Review: `/queues/:queueId/${reviewParams}`,
		Rules: `/casemanagement/${RuleRoutes.RoutingRules}`,
		Report: `/casemanagement/report`,
	},
	Environments: {
		Manage: '/environments',
	},
	Lp: {
		Reports: '/fraud/lossprevention',
	},
	Search: '/search',
	SearchResult: `/search/${reviewParams}`,
	Settings: {
		Root: '/settings',
		Usage: {
			Root: '/settings/usage',
			Summary: '/settings/usage/summary',
			Details: '/settings/usage/details',
		},
		Audit: '/settings/audit',
		AuditResult: `/settings/${reviewParams}`,
		AuditResultParams: `/${reviewParams}`,
		Rbac: '/settings/access',
		SubjectRequests: {
			Root: '/settings/subject',
			Search: '/settings/subject/search',
			Requests: '/settings/subject/requests',
		},
		Tab: {
			Root: '/settings/transactionacceptancebooster',
			Optin: '/settings/transactionacceptancebooster/optin',
		},
	},
	Data: {
		Root: '/data',
		RuleConfiguration: {
			Root: '/rulesconfiguration',
			Rules: '/data/rules/purchaserules',
		},
		Velocities: `/data/${velocitiesPath}`,
		ExternalCalls: '/data/externalcalls',
		ExternalAssessments: '/data/externalassessments',
		Lists: {
			Root: '/data/lists',
			Custom: '/data/lists/custom',
			Support: '/data/lists/support',
		},
		GraphExplorer: '/data/explorer',
		EventTracing: '/data/eventtracing',
		DataUpload: {
			Root: '/data/upload',
			Purchase: '/data/upload/purchaseprotection',
			Lp: '/data/upload/lossprevention',
		},
		ApiManagement: {
			Root: '/data/apimanagement',
			Requests: '/data/apimanagement/requests',
			Errors: '/data/apimanagement/errors',
			Ontology: '/data/apimanagement/ontology',
		},
		Templates: '/data/templates',
		GlobalFunctions: `/data/${globalFunctionsPath}`,
	},
	AdminSettings: {
		Root: '/adminsettings',
		Billing: '/adminsettings/billing',
		Search: '/adminsettings/search',
	},
	AssessmentReports: {
		Root: '/reports',
		Summary: '/reports/summary',
		Rules: '/reports/rules',
		Scores: '/reports/scores',
		Threats: '/reports/threats',
		Monitor: '/reports/monitor',
	},
};
